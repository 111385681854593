import loadable from "@loadable/component"
import React from "react"

const SwitchbackStandard = loadable(() => import("./SwitchbackStandard"))
const SwitchbackHome = loadable(() => import("./SwitchbackHome"))

const Switchback = ({ component }) => {
  const { type } = component
  switch (type) {
    case "standard":
      return <SwitchbackStandard component={component} />
    case "homepage-switchback-animations":
      return <SwitchbackHome component={component} />
    default:
      return <SwitchbackStandard component={component} />
  }
}

export default Switchback